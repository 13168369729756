import { gql } from '@apollo/client';

export const LOAD_CATEGORIES_DATA = gql`
  query loadCategoriesData($userId: String!) {
    loadEducationInfo(userId: $userId) {
      id
      userId
      schoolName
      country
      entryDate
      graduationDate
      uniqueProgression
    }
    loadMissions(userId: $userId, category: "ACADEMICS", subcategory: "School Academics") {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      members
      grade
      actionItems {
        description
      }
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
        isGPA
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        predictGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        semester
        version
        subject {
          id
          curriculumId
          name
          curriculum {
            shortName
            levels
          }
        }
      }
    }
    testMissionsData: loadTestMissionsList(userId: $userId) {
      category
      list {
        type
        list {
          id
          userId
          missionId
          missionStatus
          type
          subCategory
          testDate
          totalScore
          targetScore
          isMockTest
          members
          testResult {
            id
            studentTestId
            key
            value
          }
        }
      }
    }
    internshipExperience: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Internship & Work Experience"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      experience {
        id
        userId
        missionId
        industry
        organizationName
        programProvider
        position
        applicationDeadline
        impactFactor
      }
    }
    competitionsHonorsData: loadMissions(
      userId: $userId
      category: "COMPETITIONS_HONORS"
      subcategory: "Competitions & Honors"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      honor {
        id
        userId
        missionId
        category
        award
        awardLevel
        schoolYear
        levelOfRecognition
        resourceId
        divisionId
      }
    }
    creativePortfoliosList: loadMissions(userId: $userId, category: "ACADEMICS", subcategory: "Creative Portfolios") {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      portfolios {
        id
        type
        advisorUserId
        advisorName
        provider
        preparationStartDate
        preparationEndDate
        preparationRequired
      }
    }
    universityProgramsList: loadMissions(
      userId: $userId
      category: "ACADEMICS"
      subcategory: "University Courses & Summer Programs"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
      }
    }
    academicResearchPublicationsList: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Academic Research & Publications"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
    }
    extracurricularActivitiesList: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Extracurricular Activities"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      extracurricular {
        id
        missionId
        userId
        subType
        activityType
        schoolYear
        organizationName
        position
        timeOfParticipation
        crimsonCapstone
        hoursPerWeek
        weeksPerYear
        description
        impactFactor
        programProvider
      }
    }
  }
`;

// ---
export const LOAD_TEST_MISSIONS = gql`
  query loadTestMissionsList($userId: String!) {
    data: loadTestMissionsList(userId: $userId) {
      category
      list {
        type
        list {
          id
          userId
          missionId
          missionStatus
          type
          subCategory
          testDate
          totalScore
          targetScore
          isMockTest
          members
          testResult {
            id
            studentTestId
            key
            value
          }
        }
      }
    }
  }
`;
export const LOAD_MISSION = gql`
  query loadMissions($userId: String!, $category: String!, $subcategory: String!) {
    loadMissions(userId: $userId, category: $category, subcategory: $subcategory) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
      }
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
      }
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
      portfolios {
        id
        type
        advisorUserId
        advisorName
        provider
        preparationStartDate
        preparationEndDate
        preparationRequired
      }
      experience {
        id
        userId
        missionId
        industry
        organizationName
        programProvider
        position
        applicationDeadline
        impactFactor
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        subject {
          id
          curriculumId
          name
        }
      }
      extracurricular {
        id
        missionId
        userId
        subType
        activityType
        schoolYear
        organizationName
        position
        timeOfParticipation
        crimsonCapstone
        hoursPerWeek
        weeksPerYear
        description
        impactFactor
      }
      honor {
        id
        userId
        missionId
        category
        award
        awardLevel
        schoolYear
        levelOfRecognition
        resourceId
        divisionId
      }
      reading {
        id
        userId
        missionId
        title
        author
        type
        description
        resourceId
        bookCover
      }
      otherSubcategory {
        id
        userId
        missionId
        pathfinderDescription
        pathfinderId
        points
      }
    }
  }
`;
// ----

// no such query available yet
export const LOAD_SCHOOL_ACADEMIC_BY_ID = gql`
  query mission($missionId: String!) {
    mission(missionId: $missionId) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      members
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
        isGPA
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        subject {
          id
          curriculumId
          name
        }
      }
    }
  }
`;

export const GET_CURRICULUMS = gql`
  query getCurriculums($name: String!) {
    getCurriculums(name: $name) {
      id
      name
      shortName
      levels
      type
      maxGrade
    }
  }
`;

export const GET_CURRICULUMS_WITH_SUBJECTS = gql`
  query getCurriculums($name: String!, $withInactive: Boolean) {
    getCurriculums(name: $name, withInactive: $withInactive) {
      id
      name
      shortName
      levels
      type
      status
      maxGrade
      subjects {
        id
        name
        status
      }
    }
  }
`;

export const LOAD_SUBJECTS = gql`
  query loadSubjectsByCurriculumAndName($curriculumId: Int!, $name: String!) {
    loadSubjectsByCurriculumAndName(curriculumId: $curriculumId, name: $name) {
      id
      curriculumId
      name
    }
  }
`;

export const UPDATE_SCHOOL_WORK_INFO = gql`
  mutation addOrUpdateStudentAcademic($input: studentAcademicInput!) {
    addOrUpdateStudentAcademic(input: $input) {
      success
    }
  }
`;

export const ADD_STUDENT_SUBJECTS = gql`
  mutation addStudentSubjects($input: subjectsInput!) {
    addStudentSubjects(input: $input) {
      id
      missionId
      subjectId
      targetGrade
      actualGrade
      gradeFormat
      maxGrade
      level
      semester
      selfStudy
      subject {
        id
        curriculumId
        name
      }
    }
  }
`;

export const UPDATE_STUDENT_SUBJECTS = gql`
  mutation updateStudentSubject($input: studentSubjectInput!) {
    updateStudentSubject(input: $input) {
      success
    }
  }
`;

export const DELETE_STUDENT_SUBJECTS = gql`
  mutation deleteStudentSubject($studentSubjectId: String!) {
    deleteStudentSubject(studentSubjectId: $studentSubjectId) {
      success
    }
  }
`;

export const GET_FILES_BY_TYPE_AND_REF_ID = gql`
  query getFilesByTypeAndRefId($refType: String!, $refId: String!, $tag: String) {
    getFilesByTypeAndRefId(refType: $refType, refId: $refId, tag: $tag) {
      id
      name
      description
      size
      type
      location
    }
  }
`;

export const SAVE_FILE = gql`
  mutation saveFileUplRef(
    $location: String!
    $sha512: String!
    $name: String!
    $size: String!
    $type: String!
    $refType: String!
    $refId: String!
    $tag: String
  ) {
    saveFileUplRef(
      location: $location
      sha512: $sha512
      name: $name
      size: $size
      type: $type
      refType: $refType
      refId: $refId
      tag: $tag
    ) {
      name
    }
  }
`;

export const GET_SC_UPLOAD_URL = gql`
  query getStudentCenterUploadUrl($refId: String!, $fileName: String!, $contentType: String!) {
    getStudentCenterUploadUrl(refId: $refId, fileName: $fileName, contentType: $contentType) {
      putUrl
      bucket
      key
    }
  }
`;

export const GET_SC_DOWNLOAD_URL = gql`
  query getStudentCenterDownloadUrl($fileId: String!) {
    url: getStudentCenterDownloadUrl(fileId: $fileId)
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFileByFileId($fileId: String!) {
    deleteFileByFileId(fileId: $fileId) {
      id
    }
  }
`;

export const LOAD_CURRENT_CLASS_RANK = gql`
  query Query($userId: String!) {
    loadStudentCurrentClassRank(userId: $userId)
  }
`;

export const LOAD_ACADEMICS_DATA = gql`
  query loadAcademicsData($userId: String!) {
    loadEducationInfo(userId: $userId) {
      id
      userId
      schoolName
      country
      entryDate
      graduationDate
      uniqueProgression
    }
    schoolAcademics: loadMissions(userId: $userId, category: "ACADEMICS", subcategory: "School Academics") {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      members
      grade
      actionItems {
        description
      }
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
        isGPA
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        predictGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        semester
        version
        subject {
          id
          curriculumId
          name
          curriculum {
            shortName
            levels
          }
        }
      }
    }
    universityProgramsList: loadMissions(
      userId: $userId
      category: "ACADEMICS"
      subcategory: "University Courses & Summer Programs"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
      }
    }
    academicResearchPublicationsList: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Academic Research & Publications"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
    }
  }
`;

export const LOAD_ACTIVITIES_DATA = gql`
  query loadActivitiesData($userId: String!) {
    extracurricularActivitiesList: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Extracurricular Activities"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      extracurricular {
        id
        missionId
        userId
        subType
        activityType
        schoolYear
        organizationName
        position
        timeOfParticipation
        crimsonCapstone
        hoursPerWeek
        weeksPerYear
        description
        impactFactor
        programProvider
      }
    }
    universityProgramsList: loadMissions(
      userId: $userId
      category: "ACADEMICS"
      subcategory: "University Courses & Summer Programs"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
        applicationDeadline
        occurrence
      }
    }
    academicResearchPublicationsList: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Academic Research & Publications"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
    }
    internshipExperience: loadMissions(
      userId: $userId
      category: "ACTIVITIES"
      subcategory: "Internship & Work Experience"
    ) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      experience {
        id
        userId
        missionId
        industry
        organizationName
        programProvider
        position
        applicationDeadline
        impactFactor
      }
    }
  }
`;

export const LOAD_HONORS_DATA = gql`
  query loadHonorsData($userId: String!) {
    data: loadMissions(userId: $userId, category: "COMPETITIONS_HONORS", subcategory: "Competitions & Honors") {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      honor {
        id
        userId
        missionId
        category
        award
        awardLevel
        schoolYear
        levelOfRecognition
        resourceId
        divisionId
      }
    }
  }
`;
