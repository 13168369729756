import styled from 'styled-components';
import { Input, DatePicker, Select, Checkbox as AntCheckbox } from 'antd';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { StyledButtonFilled, StyledButtonOutlined } from 'src/web-shared-components/base/Buttons';
const { RangePicker } = DatePicker;

export const StyledMissionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMissionLeftContainer = styled.div`
  display: flex;
`;
export const StyledMissionIcon = styled.div<{ type?: string }>`
  width: 60px;
  height: 60px;
  background: ${(props) => {
    if (props.type) {
      switch (props.type) {
        case 'program':
          return '#fff2ef';
        case 'research':
          return 'rgba(108, 99, 255, 0.15)';
        case 'portfolios':
          return 'rgba(59, 134, 254, 0.15);';
        case 'experience':
          return 'rgba(253, 170, 2, 0.15);';
      }
    }
  }};
  img {
    width: 50%;
  }
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
`;
export const StyledMissionTitleContainer = styled.div`
  font-size: 16px;
  input {
    font-size: 16px;
    padding: 0;
    font-weight: 600;
  }
  input::placeholder {
    color: #a9acc0;
  }
`;
export const StyledMissionTitle = styled(Input)`
  font-size: 16px;
  padding: 0;
`;
export const StyledInput = styled(Input)`
  font-size: 12px;
  width: auto;
  padding-left: 0;
`;

export const StyledSelect = styled(Select)`
  font-size: 12px;
  .ant-select-selector {
    height: 27px !important;
    padding: 0 7px 0 0 !important;
  }
  .ant-select-selection-placeholder {
    padding-right: 26px !important;
  }
`;

export const StyledMissionItem = styled.div<{ active?: boolean }>`
  margin-top: 32px;
  border-radius: 4px;
  box-shadow: 0px -6px 20px rgb(186 186 186 / 10%);
  padding: 12px 12px 5px 12px;
  &:hover {
    box-shadow: ${(props) =>
      props?.active ? '0px 1px 20px rgb(186, 199, 218, 0.3)' : '0px -6px 20px rgb(186 186 186 / 10%)'};
  }
`;

export const StyledAddButtonArea = styled.div`
  margin-top: 10px;
  padding-left: 22px;
`;
export const StyledMissionStatusText = styled.div<{ status?: string; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
`;
export const StyledMissionStatus = styled.div<{ status?: string; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#e9e8ff';
        case 'DONE':
          return '#E0F9F2';
        case 'IN_PROGRESS':
          return '#E8F1FF';
        case 'EXPIRED':
          return '#ffeae4';
        case 'POSTPONED':
          return '#fff2d9';
      }
    }
  }};
  border-radius: 8px;
  padding: 5px 13px;
  color: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#6c63ff';
        case 'DONE':
          return '#12C39A';
        case 'IN_PROGRESS':
          return '#3F8CFF';
        case 'EXPIRED':
          return '#ff764c';
        case 'POSTPONED':
          return '#fda903';
      }
    }
  }};
`;
export const StyledRightOutlined = styled(RightOutlined)`
  color: #a9acc0;
`;
export const StyledMissionRightContainer = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
`;
export const StyledRemoveButton = styled.div`
  margin-left: 16px;
`;
export const StyledRangePicker = styled(RangePicker)`
  margin-left: 8px;
  .ant-picker-suffix {
    color: #a9acc0;
  }
  padding: 0px !important;
  .ant-picker-input {
    width: 85px;
  }
  .ant-picker-input > input {
    font-size: 12px !important;
    color: #73747d;
  }
  input::placeholder {
    color: #73747d;
  }
  .ant-picker-suffix {
    height: auto;
  }
  .ant-picker-active-bar {
    margin-left: 0px;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  padding-left: 0px !important;
  cursor: pointer;
  .ant-picker-input > input {
    font-size: 12px !important;
  }
  .ant-picker-suffix {
    height: auto;
  }
`;
export const StyledMissionTableInputContainer = styled.div`
  input::placeholder {
    color: #73747d;
  }
`;

export const StyledUploadContainer = styled.div`
  .ant-btn {
    background: transparent;
    border-bottom: 1px solid;
  }
  .ant-upload-list {
    max-width: 148px !important;
  }
`;
export const StyledDatePickerContainer = styled.div`
  .ant-picker-input {
    height: 18px;
  }
`;
export const StyledCrimssonTrackingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #f4f5ff;
  border-radius: 8px;
  padding: 12px 24px;
  color: #1d1e2b;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s;
  .set-total-number {
    cursor: pointer;
    margin-left: 16px;
    color: #464ac9;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  &:hover .set-total-number {
    opacity: 0.8;
  }
`;

export const StyledCrimssonTrackingReveal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCrimssonTrackingModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const StyledModalContainer = styled.div`
  text-align: center;
  .ant-input-number {
    width: 100%;
  }
`;

export const StyledModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

export const StyledModalDesc = styled.div`
  margin-top: 25px;
  color: #1d1e2b;
  display: flex;
  flex-direction: column;
  > div:first-child {
    margin-bottom: 14px;
    line-height: 20px;
  }
`;

export const StyledButtonGroup = styled.div`
  margin-top: 35px;
  display: flex;
  margin-bottom: 32px;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
`;
export const SubmitButton = styled(StyledButtonFilled)`
  background: #464ac9;
  padding: 20px;
`;

export const StyledProportionBox = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin: 0 10px 0 14px;
  }
`;

export const StyledProportion = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
  border-radius: 8px;
  background: #ffffff;
  margin-left: 13px;
  overflow: hidden;
  div {
    transition: width 0.5s;
  }
`;

export const StyledErrorInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: -2px;
  margin-right: 10px;
  color: #ed4b53;
  cursor: pointer;
  svg {
    width: 17px;
    height: 17px;
  }
`;

export const StyledExportPDFButton = styled.div`
  margin-left: 24px;
  border-radius: 4px;
  border: 1px solid var(--Pale-grey, #e3e7ed);
  background: var(--blue-baby-blue, #f4f5ff);
  box-shadow: 0px 1px 2px 0px rgba(27, 29, 46, 0.05);
  display: flex;
  padding: 4px 12px 4px 8px;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
`;

export const StyledExportPDFText = styled.div`
  color: var(--Indigo, #464ac9);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const SelectSummerProgramTitle = styled.div`
  width: 100%;
  text-align: center;
  color: #1d1e2b;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 34px;
`;

export const SelectSummerProgramDescription = styled.div`
  color: var(--grey-stone, #73747d);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 18px;
`;
export const SummerProgramName = styled.div`
  color: var(--blue-dark-navy, #1d1e2b);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
`;

export const Checkbox = styled(AntCheckbox)`
  margin-top: 18px;
  float: left;
  cursor: pointer;
  color: #73747d;
  size: 14px;
  line-height: 20px;
  weight: 500;
`;

export const ZeroQuotaArea = styled.div`
  display: flex;
  flex-direction: column;
`;
