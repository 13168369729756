import { PDFDataType } from './Hooks';
import { Font, Page, Text, Document, StyleSheet, PDFViewer, View, Svg, G, Path } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import React, { useState, useEffect, useRef, useCallback, memo } from 'react';

import * as Logger from '@crimson-education/browser-logger';

import HanSansRegular from './HanSans/Light.ttf';
import HanSansSemiBold from './HanSans/Regular.ttf';
import HanSansMedium from './HanSans/Medium.ttf';
import HanSansBold from './HanSans/Bold.ttf';
import { Spin } from 'antd';

Font.register({
  family: 'HanSans',
  fonts: [
    { src: HanSansRegular, fontWeight: 'normal' }, // font-style: normal, font-weight: normal
    { src: HanSansMedium, fontWeight: 500 },
    { src: HanSansSemiBold, fontWeight: 600 },
    { src: HanSansBold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  line: {
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    wordWrap: 'break-word',
    maxWidth: '70%',
  },
  body: {
    paddingTop: 30,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    fontFamily: 'HanSans',
    flexDirection: 'row',
    display: 'flex',
    fontSize: 12,
    marginBottom: 10,
  },
  flexColumn: {
    flexDirection: 'column',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'flex-start',
  },
  logo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 25,
  },
  topText: {
    fontSize: '10px',
    color: '#73747D',
    marginLeft: '85px',
  },
  boldText: {
    width: '50%',
    fontFamily: 'HanSans',
    fontWeight: 700,
  },
  semiBoldText: {
    fontFamily: 'HanSans',
    fontWeight: 600,
  },
  leftText: {
    width: '30%',
  },
  mediumText: {
    fontFamily: 'HanSans',
    fontWeight: 500,
  },
  description: {
    maxWidth: '70%',
    fontSize: '10px',
  },
  stone: {
    color: '#000000',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '160%',
    marginBottom: '0px',
    backgroundColor: '#F3F6FA',
    height: '32px',
    transform: 'translate(-35px , -30px)',
  },
  sectionTitle: {
    fontSize: '12px',
    marginBottom: 8,
    marginTop: 8,
    color: '#464AC9',
  },
  sectionSubTitle: {
    marginBottom: 8,
    color: '#1D1E2B',
    fontSize: '10px',
  },
  profileSection: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: '12px',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  divider: {
    borderBottom: '1px solid black',
    width: '100%',
    height: '1px',
    marginBottom: '16px',
  },
  font10: {
    fontSize: '10px',
    marginBottom: '12px',
  },
  categoryLeft: {
    color: '#000000',
    textAlign: 'left',
    width: '45%',
    fontSize: '10px',
  },
  categoryRight: {
    color: '#000000',
    fontSize: '10px',
    width: '70%',
  },
});

const activityStyle = StyleSheet.create({
  activityTitle: {
    marginTop: 18,
    padding: 0,
    marginBottom: 3,
    fontWeight: 600,
    color: '#000000',
  },
  dividerDashed: {
    borderBottom: '1px dashed #E3E7ED',
    width: '100%',
    marginBottom: 6,
  },
  fullWidth: {
    width: '100%',
    color: '#000000',
  },
  margin20: {
    marginTop: '12px',
    marginBottom: '10px',
  },
  activityLeftProperties: {
    color: '#000000',
    textAlign: 'left',
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
  },
  activityRightProperties: {
    width: '70%',
  },
  activityRightDescriptions: {
    fontFamily: 'HanSans',
    fontSize: '8px',
  },
});

const Logo = (): JSX.Element => (
  <Svg
    width="109"
    height="48"
    viewBox="0 0 109 48"
    style={{
      transform: 'scale(0.75)',
    }}
  >
    <G clip-path="url(#clip0_12742_47694)">
      <Path
        d="M14.0792 9.60778L12.3408 6.6011C12.2789 6.49386 12.1899 6.40481 12.0826 6.34289C11.9754 6.28098 11.8537 6.24838 11.7299 6.24838C11.6061 6.24838 11.4844 6.28098 11.3772 6.34289C11.2699 6.40481 11.1809 6.49386 11.1189 6.6011L7.3117 13.1017C7.18843 13.3152 7.12354 13.5574 7.12354 13.804C7.12354 14.0505 7.18843 14.2927 7.3117 14.5062L9.22829 17.7473C10.2695 14.73 11.9207 11.9593 14.0792 9.60778Z"
        fill="#AA1F25"
      />
      <Path
        d="M47.6714 34.0653C47.0505 34.3377 46.3776 34.4708 45.6998 34.4554C45.0598 34.463 44.4241 34.3494 43.8264 34.1204C43.2684 33.9117 42.7597 33.5898 42.3321 33.175C41.9044 32.7602 41.5672 32.2615 41.3415 31.7101C40.8608 30.4955 40.8608 29.1434 41.3415 27.9288C41.5718 27.3756 41.9177 26.8779 42.356 26.4692C42.7922 26.0613 43.3068 25.7463 43.8685 25.5433C44.4674 25.3255 45.1003 25.2158 45.7376 25.2192C46.3609 25.2189 46.9793 25.3286 47.5645 25.5433C48.1226 25.7361 48.6231 26.0664 49.0198 26.5038L47.5818 27.9375C47.3847 27.6665 47.115 27.4568 46.8039 27.3324C46.49 27.2026 46.1539 27.1351 45.8143 27.1337C45.4629 27.1303 45.1146 27.1994 44.7912 27.3368C44.4882 27.4666 44.2147 27.6566 43.9874 27.8953C43.7587 28.1395 43.5816 28.4272 43.4666 28.7413C43.3403 29.0851 43.2774 29.4489 43.2808 29.8151C43.2774 30.1856 43.3403 30.5537 43.4666 30.902C43.5814 31.2152 43.7569 31.5027 43.983 31.7479C44.203 31.9852 44.4712 32.1727 44.7696 32.2978C45.0866 32.4321 45.4279 32.4998 45.7721 32.4966C46.1533 32.5044 46.5305 32.4187 46.8709 32.2471C47.1704 32.0959 47.4297 31.8755 47.6271 31.6042L49.0986 32.9914C48.7067 33.449 48.2196 33.8155 47.6714 34.0653Z"
        fill="#AA1F25"
      />
      <Path
        d="M56.1557 34.222L54.2521 30.7421H53.5337V34.222H51.4658V25.4547H54.8063C55.2215 25.4537 55.6356 25.4979 56.0412 25.5865C56.4221 25.66 56.7862 25.8033 57.1151 26.009C57.4347 26.2115 57.697 26.4928 57.8768 26.8257C58.0805 27.2151 58.1795 27.6506 58.1641 28.0898C58.1822 28.631 58.0119 29.1618 57.6823 29.5915C57.3309 30.0021 56.8635 30.297 56.3416 30.4374L58.6341 34.1885L56.1557 34.222ZM56.0715 28.1405C56.0804 27.9645 56.0343 27.7901 55.9397 27.6414C55.8552 27.515 55.7404 27.4117 55.6058 27.3411C55.4599 27.2716 55.3044 27.2247 55.1445 27.2017C54.9822 27.1767 54.8182 27.164 54.654 27.1639H53.5207V29.2069H54.5276C54.7085 29.2072 54.8892 29.1928 55.0678 29.1637C55.24 29.1385 55.407 29.086 55.5626 29.0081C55.7129 28.9365 55.8402 28.8242 55.9299 28.684C56.0317 28.5216 56.0811 28.3319 56.0715 28.1405Z"
        fill="#AA1F25"
      />
      <Path d="M61.1123 34.222V25.4547H63.2266V34.222H61.1123Z" fill="#AA1F25" />
      <Path
        d="M74.4768 34.222L74.5276 28.0185H74.4897L72.2145 34.222H70.7257L68.5131 28.0185H68.4753L68.5131 34.222H66.522V25.4547H69.533L71.5284 31.0727H71.5792L73.4872 25.4547H76.5489V34.222H74.4768Z"
        fill="#AA1F25"
      />
      <Path
        d="M84.4443 27.7343C84.2561 27.4968 84.0144 27.3073 83.7388 27.1812C83.4752 27.0523 83.1864 26.9829 82.8929 26.9781C82.7509 26.9783 82.6092 26.9909 82.4694 27.0159C82.3325 27.0388 82.2008 27.086 82.0805 27.1553C81.9652 27.2255 81.8659 27.319 81.7888 27.4297C81.7057 27.5548 81.6644 27.7031 81.671 27.8532C81.6656 27.9834 81.6979 28.1124 81.7639 28.2248C81.8333 28.3302 81.9256 28.4185 82.034 28.483C82.1684 28.5653 82.3102 28.6347 82.4575 28.6905L83.015 28.8806C83.311 28.9779 83.6243 29.0881 83.9463 29.2112C84.2599 29.3245 84.5549 29.484 84.8214 29.6844C85.0859 29.8906 85.3063 30.1478 85.4696 30.4407C85.6525 30.7772 85.7417 31.1567 85.7278 31.5394C85.7402 31.9899 85.6454 32.4368 85.4512 32.8434C85.2741 33.2009 85.0181 33.5134 84.7025 33.7574C84.3781 33.9998 84.0119 34.1803 83.6222 34.2901C83.2163 34.4057 82.7963 34.4639 82.3743 34.4629C81.7519 34.4642 81.1343 34.3526 80.5517 34.1334C79.9958 33.9328 79.4935 33.6072 79.0835 33.1816L80.4707 31.7695C80.702 32.0487 80.9904 32.2749 81.3166 32.4329C81.6398 32.6038 81.999 32.6952 82.3646 32.6998C82.5184 32.7002 82.6717 32.6832 82.8216 32.649C82.9604 32.6209 83.0925 32.5662 83.2105 32.488C83.3246 32.4157 83.4177 32.3147 83.4806 32.1952C83.5513 32.0566 83.5862 31.9024 83.5822 31.7469C83.5879 31.5958 83.543 31.4471 83.4547 31.3244C83.3608 31.1959 83.2417 31.0878 83.1047 31.0068C82.9245 30.8998 82.7327 30.8135 82.5331 30.7497C82.3106 30.6697 82.0527 30.5851 81.7596 30.4958C81.4796 30.4045 81.2054 30.2963 80.9385 30.1717C80.675 30.0538 80.4324 29.894 80.2201 29.6985C80.0056 29.499 79.833 29.2588 79.7123 28.9919C79.5737 28.6733 79.5072 28.328 79.5178 27.9807C79.5028 27.5386 79.6065 27.1006 79.8182 26.7123C80.0102 26.3752 80.2762 26.086 80.596 25.8664C80.9279 25.6407 81.2974 25.476 81.6872 25.3802C82.0875 25.2753 82.4996 25.2227 82.9134 25.2236C83.4296 25.229 83.941 25.3221 84.426 25.499C84.9272 25.6704 85.3884 25.9417 85.7818 26.2964L84.4443 27.7343Z"
        fill="#AA1F25"
      />
      <Path
        d="M97.614 29.8033C97.6278 30.4553 97.5113 31.1036 97.2715 31.7101C97.0457 32.27 96.7024 32.7749 96.2648 33.1907C95.8272 33.6065 95.3054 33.9236 94.7348 34.1204C93.5053 34.5666 92.1581 34.5666 90.9286 34.1204C90.3644 33.9125 89.8494 33.5899 89.4161 33.1729C88.9862 32.7602 88.6475 32.2618 88.4221 31.7101C87.9413 30.487 87.9413 29.1271 88.4221 27.904C88.6496 27.3583 88.9898 26.8668 89.4204 26.4617C89.8556 26.0567 90.3677 25.7433 90.9264 25.5401C92.1589 25.1111 93.5001 25.1111 94.7326 25.5401C95.2939 25.7398 95.8085 26.0519 96.2451 26.4573C96.6839 26.8615 97.0312 27.3547 97.2639 27.904C97.506 28.5074 97.625 29.1532 97.614 29.8033ZM95.3452 29.8033C95.3449 29.4436 95.2805 29.0869 95.155 28.7499C95.0404 28.4375 94.8666 28.1503 94.6429 27.904C94.418 27.6674 94.1475 27.4788 93.8478 27.3497C93.527 27.2084 93.1795 27.1377 92.829 27.1423C92.4798 27.1371 92.1336 27.2079 91.8145 27.3497C91.5166 27.48 91.2478 27.6684 91.0237 27.904C90.7925 28.1461 90.615 28.4344 90.5029 28.7499C90.3775 29.092 90.3153 29.454 90.3193 29.8184C90.3158 30.1917 90.3788 30.5626 90.5051 30.9139C90.6195 31.2273 90.795 31.5148 91.0215 31.7598C91.2398 32.0022 91.5082 32.1942 91.808 32.3227C92.1272 32.4645 92.4733 32.5353 92.8225 32.5301C93.1719 32.5341 93.5182 32.4634 93.8381 32.3227C94.1373 32.191 94.4065 31.9995 94.6289 31.7598C94.8572 31.5157 95.034 31.2279 95.1485 30.9139C95.2811 30.5586 95.3484 30.1824 95.3473 29.8033H95.3452Z"
        fill="#AA1F25"
      />
      <Path
        d="M106.027 34.222L102.499 28.4874H102.461L102.512 34.222H100.459V25.4547H102.874L106.389 31.1807H106.427L106.377 25.4547H108.429V34.222H106.027Z"
        fill="#AA1F25"
      />
      <Path
        d="M6.34375 0.346806L8.64279 4.32151C8.76606 4.53502 8.83096 4.77721 8.83096 5.02375C8.83096 5.27029 8.76606 5.51249 8.64279 5.726L6.34375 9.72338C6.28116 9.83174 6.19115 9.92172 6.08277 9.98428C5.97439 10.0468 5.85146 10.0798 5.72632 10.0798C5.60118 10.0798 5.47825 10.0468 5.36987 9.98428C5.26149 9.92172 5.17148 9.83174 5.10889 9.72338L2.79148 5.726C2.66675 5.51062 2.60107 5.26615 2.60107 5.01727C2.60107 4.76839 2.66675 4.52391 2.79148 4.30854L5.12185 0.342484C5.18546 0.237377 5.27511 0.150459 5.38214 0.0901311C5.48916 0.0298037 5.60994 -0.00189209 5.7328 -0.00189209C5.85566 -0.00189209 5.97644 0.0298037 6.08346 0.0901311C6.19049 0.150459 6.28014 0.237377 6.34375 0.342484V0.346806Z"
        fill="#AA1F25"
      />
      <Path
        d="M2.91681 10.4926L4.3818 13.0293C4.50507 13.2428 4.56996 13.485 4.56996 13.7316C4.56996 13.9781 4.50507 14.2203 4.3818 14.4338L2.91681 16.9673C2.85378 17.0751 2.76362 17.1645 2.65529 17.2267C2.54697 17.2888 2.42426 17.3215 2.29937 17.3215C2.17449 17.3215 2.05178 17.2888 1.94346 17.2267C1.83513 17.1645 1.74497 17.0751 1.68194 16.9673L0.189941 14.4295C0.0652035 14.2143 -0.000488281 13.97 -0.000488281 13.7213C-0.000488281 13.4726 0.0652035 13.2283 0.189941 13.0131L1.6787 10.4796C1.74243 10.3714 1.83356 10.282 1.9429 10.2202C2.05225 10.1585 2.17594 10.1267 2.3015 10.128C2.42707 10.1293 2.55007 10.1637 2.65809 10.2277C2.76612 10.2917 2.85536 10.3831 2.91681 10.4926Z"
        fill="#AA1F25"
      />
      <Path
        d="M50.6909 13.5241C44.3459 2.62423 30.3658 -1.06957 19.4648 5.27655C17.4588 6.44356 15.6426 7.90996 14.0791 9.62506L16.8741 10.323C16.958 10.3436 17.0361 10.3835 17.1019 10.4396C17.1677 10.4957 17.2195 10.5664 17.2532 10.646C17.2869 10.7256 17.3015 10.812 17.2959 10.8983C17.2903 10.9846 17.2646 11.0684 17.2209 11.143L13.11 18.2735C13.0341 18.4055 12.9167 18.5089 12.7761 18.5675C12.6355 18.6261 12.4794 18.6366 12.3322 18.5976L9.24446 17.7516C9.37195 17.3876 9.50699 17.0246 9.66797 16.6648C8.2602 20.1696 7.75139 23.9708 8.18786 27.7224C9.64636 40.298 21.0216 49.304 33.5994 47.8455C35.8704 47.5818 38.0892 46.9799 40.1821 46.0596C40.4816 45.9247 40.7152 45.6764 40.8317 45.3693C40.9482 45.0622 40.938 44.7214 40.8033 44.4218C40.7957 44.4034 40.7871 44.3861 40.7785 44.3678L38.0667 39.0394C37.9287 38.771 37.6973 38.5623 37.4162 38.4524C37.1351 38.3426 36.8236 38.3393 36.5401 38.443C35.8408 38.6915 35.1259 38.8936 34.3999 39.048C25.5949 40.93 29.8915 37.2459 27.6 35.2418C26.3306 34.1161 23.2526 33.1265 20.8833 33.0465C15.5722 32.8304 15.6349 30.8977 16.6548 28.7412C17.357 27.2481 17.0307 26.4195 17.1701 25.781C17.3095 25.1425 17.3181 24.8843 17.6401 24.5548C18.1262 24.0513 17.7416 23.3912 17.3224 22.8078C16.3793 21.4962 16.6288 20.6471 17.9404 20.3802C19.252 20.1133 21.9378 18.7099 22.1398 17.6101C22.3419 16.5103 24.064 11.9922 27.7221 11.1052C33.7571 9.64235 39.9617 11.5287 43.4124 17.4113C43.5711 17.6865 43.829 17.8906 44.1333 17.9818C44.4376 18.073 44.7653 18.0444 45.0492 17.9018L50.2047 15.2625C50.3492 15.1875 50.4774 15.0849 50.5821 14.9604C50.6868 14.8359 50.766 14.6919 50.8151 14.5368C50.8641 14.3817 50.8821 14.2184 50.8681 14.0564C50.854 13.8943 50.8081 13.7365 50.733 13.5922C50.7168 13.5695 50.705 13.5468 50.6909 13.5241Z"
        fill="#333557"
      />
    </G>
  </Svg>
);

const DocComponent = ({
  data,
  onPDFDocumentRendered,
  studentId,
  userId,
  isLevel3,
}: {
  data: PDFDataType;
  onPDFDocumentRendered: (onceRenderTime: number) => void;
  studentId?: string;
  userId?: string;
  isLevel3: boolean;
}): JSX.Element => {
  const timeRef = useRef({
    startTime: 0,
    onceRenderTime: 0,
    render: 0,
  });

  useEffect(() => {
    const startTime = performance.now();
    timeRef.current.startTime = startTime;
  }, []);

  timeRef.current.render += 1;

  return (
    <Document
      title="Preview"
      onRender={() => {
        const endTime = performance.now();
        timeRef.current.onceRenderTime = endTime - timeRef.current.startTime;
        timeRef.current.startTime = endTime;
        onPDFDocumentRendered(timeRef.current.onceRenderTime);
        const pdfDocumentRenderMetadata = {
          studentId: studentId,
          userId: userId,
          onceRenderTime: timeRef.current.onceRenderTime,
        };
        Logger.trackEvent({
          message: 'preview_pdf_document_performance',
          metadata: pdfDocumentRenderMetadata,
        });
      }}
    >
      <Page style={styles.body}>
        <View style={styles.top}>
          <Text style={styles.topText}>
            This PDF carries sensitive information. Please refrain from sharing it with unrelated parties.
          </Text>
        </View>
        <View
          style={[
            {
              paddingTop: 10,
              transform: 'translateY(-18px)',
            },
          ]}
        >
          {isLevel3 && (
            <View style={styles.logo}>
              <Logo />
            </View>
          )}
          <Text style={[styles.sectionTitle, styles.boldText]}>Profile</Text>
          <View style={styles.divider} />
          <View style={styles.profileSection}>
            <View style={styles.flexRow}>
              <Text style={[styles.semiBoldText, styles.categoryLeft]}>Name:</Text>
              <Text
                style={[
                  {
                    marginLeft: 8,
                  },
                  styles.categoryRight,
                ]}
              >
                {data?.profile?.Name}
              </Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={[styles.semiBoldText, styles.categoryLeft]}>Application Year:</Text>
              <Text
                style={[
                  {
                    marginLeft: 8,
                  },
                  styles.categoryRight,
                ]}
              >
                {data?.profile?.['Application Year']}
              </Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={[styles.semiBoldText, styles.categoryLeft]}>Intended Majors:</Text>
              <Text
                style={[
                  {
                    marginLeft: 8,
                  },
                  styles.categoryRight,
                ]}
              >
                {data?.profile?.['Intended Majors']}
              </Text>
            </View>
          </View>
          <Text style={[styles.sectionTitle, styles.boldText]}>Education</Text>
          <View style={styles.divider} />
          <View style={styles.flexRow}>
            <Text style={[styles.semiBoldText, styles.categoryLeft]}>Current or Most Recent Secondary School:</Text>
            <Text
              style={[
                {
                  marginLeft: 8,
                },
                styles.categoryRight,
              ]}
            >
              {data?.education.school}
            </Text>
          </View>
          {data?.education.graduationDate && (
            <View style={styles.flexRow}>
              <Text style={[styles.semiBoldText, styles.categoryLeft]}>Graduation Date:</Text>
              <Text
                style={[
                  {
                    marginLeft: 8,
                  },
                  styles.categoryRight,
                ]}
              >
                {dayjs(data?.education.graduationDate).format('MMM, YYYY')}
              </Text>
            </View>
          )}
          <View style={styles.flexRow}>
            <Text style={[styles.semiBoldText, styles.categoryLeft]}>Curriculum:</Text>
            <Text
              style={[
                {
                  marginLeft: 8,
                },
                styles.categoryRight,
              ]}
            >
              {data?.education.curriculum}
            </Text>
          </View>
          {data?.education?.schoolWorks?.length > 0 &&
            data?.education?.schoolWorks?.map((work) => (
              <View style={styles.flexRow} key={work.title}>
                <Text
                  style={[
                    styles.semiBoldText,
                    styles.sectionSubTitle,
                    styles.categoryLeft,
                    {
                      border: '1px solid white',
                    },
                  ]}
                >
                  {work.title}:
                </Text>
                <Text
                  style={[
                    {
                      marginLeft: 8,
                      border: '1px solid white',
                    },
                    styles.categoryRight,
                  ]}
                >
                  {work?.subjects}
                </Text>
              </View>
            ))}
        </View>
        {data?.testing.length > 0 && (
          <>
            <Text style={[styles.sectionTitle, styles.boldText]}>Testing</Text>
            <View style={styles.divider} />
            {data?.testing?.map((test) => (
              <View style={styles.flexRow} key={test?.title}>
                <Text style={[styles.semiBoldText, styles.categoryLeft]}>{test?.title}</Text>
                <Text
                  style={[
                    {
                      marginLeft: 8,
                    },
                    styles.categoryRight,
                  ]}
                >
                  {test?.description}
                </Text>
              </View>
            ))}
          </>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      <Page style={styles.body}>
        {data?.activities?.extracurricular?.length && (
          <Text style={[styles.sectionTitle, styles.boldText]}>Activities</Text>
        )}
        {data?.activities?.extracurricular?.length > 0 && (
          <>
            <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.fullWidth]}>
              Extracurricular Activities
            </Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.activities?.extracurricular?.length > 0 &&
          data?.activities?.extracurricular?.map((extracurricular) => (
            <React.Fragment key={extracurricular.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {extracurricular.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties]}>
                  {extracurricular.left &&
                    extracurricular.left.length > 0 &&
                    extracurricular.left.map((line) => (
                      <Text style={styles.line} key={line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {extracurricular.right &&
                    extracurricular.right.length > 0 &&
                    extracurricular.right.map((line, index) => (
                      <View key={line} style={[{ flexDirection: 'row', flexWrap: 'wrap' }]}>
                        {Array.from(line).map((char) => (
                          <Text
                            key={char}
                            style={[
                              styles.description,
                              activityStyle.activityRightDescriptions,
                              styles.line,
                              index === 0 ? styles.semiBoldText : styles.stone,
                              index === 0 ? styles.font10 : {},
                            ]}
                          >
                            {char}
                          </Text>
                        ))}
                      </View>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}
        {/* 2 */}
        {data?.activities?.universityCoursesSummerPrograms?.length > 0 && (
          <>
            <Text
              style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.fullWidth, activityStyle.margin20]}
            >
              Summer Programs & University Courses
            </Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.activities?.universityCoursesSummerPrograms?.length > 0 &&
          data?.activities?.universityCoursesSummerPrograms?.map((university) => (
            <React.Fragment key={university.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {university.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties, styles.mediumText]}>
                  {university.left &&
                    university.left.length > 0 &&
                    university.left.map((line) => (
                      <Text key={line} style={styles.line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {university.right &&
                    university.right.length > 0 &&
                    university.right.map((line) => (
                      <Text style={[styles.stone, styles.description, styles.line]} key={line}>
                        {line}
                      </Text>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}

        {data?.activities?.academicResearchPublications?.length > 0 && (
          <>
            <Text
              style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.fullWidth, activityStyle.margin20]}
            >
              Academic Research & Publications
            </Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.activities?.academicResearchPublications?.length > 0 &&
          data?.activities?.academicResearchPublications.map((academic) => (
            <React.Fragment key={academic.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {academic.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties, styles.mediumText]}>
                  {academic.left &&
                    academic.left.length > 0 &&
                    academic.left.map((line) => (
                      <Text key={line} style={styles.line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {academic.right &&
                    academic.right.length > 0 &&
                    academic.right.map((line) => (
                      <View key={line} style={[{ flexDirection: 'row', flexWrap: 'wrap' }]}>
                        {Array.from(line).map((char) => (
                          <Text key={char} style={[styles.description, styles.stone, styles.line]}>
                            {char}
                          </Text>
                        ))}
                      </View>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}

        {data?.activities?.internshipWorkExperience?.length > 0 && (
          <>
            <Text
              style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.fullWidth, activityStyle.margin20]}
            >
              Internship & Work Experience
            </Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.activities?.internshipWorkExperience?.length > 0 &&
          data?.activities?.internshipWorkExperience.map((experience) => (
            <React.Fragment key={experience.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {experience.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties, styles.mediumText]}>
                  {experience.left &&
                    experience.left.length > 0 &&
                    experience.left.map((line) => (
                      <Text key={line} style={styles.line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {experience.right &&
                    experience.right.length > 0 &&
                    experience.right.map((line) => (
                      <Text style={[styles.stone, styles.description, styles.line]} key={line}>
                        {line}
                      </Text>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}
        {/* 5 */}
        {/* creativePortfolios */}
        {data?.activities?.creativePortfolios?.length > 0 && (
          <>
            <Text
              style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.fullWidth, activityStyle.margin20]}
            >
              Creative Portfolios
            </Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.activities?.creativePortfolios?.length > 0 &&
          data?.activities?.creativePortfolios.map((portfolios) => (
            <React.Fragment key={portfolios.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {portfolios.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties, styles.mediumText]}>
                  {portfolios.left &&
                    portfolios.left.length > 0 &&
                    portfolios.left.map((line) => (
                      <Text key={line} style={styles.line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {portfolios.right &&
                    portfolios.right.length > 0 &&
                    portfolios.right.map((line) => (
                      <Text style={[styles.stone, styles.description, styles.line]} key={line}>
                        {line}
                      </Text>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}
        {data?.competitionsHonors?.length > 0 && (
          <>
            <Text style={[styles.sectionTitle, styles.boldText]}>Competitions & Honors</Text>
            <View
              style={[
                styles.divider,
                {
                  marginBottom: 0,
                },
              ]}
            />
          </>
        )}
        {data?.competitionsHonors?.length > 0 &&
          data?.competitionsHonors?.map((competitionsHonor) => (
            <React.Fragment key={competitionsHonor.id}>
              <Text style={[styles.semiBoldText, styles.sectionSubTitle, activityStyle.activityTitle]}>
                {competitionsHonor.title || 'General'}
              </Text>
              <View style={activityStyle.dividerDashed} />
              <View style={styles.flexRow}>
                <View style={[styles.description, activityStyle.activityLeftProperties, styles.mediumText]}>
                  {competitionsHonor.left &&
                    competitionsHonor.left.length > 0 &&
                    competitionsHonor.left.map((line) => (
                      <Text key={line} style={styles.line}>
                        {line}
                      </Text>
                    ))}
                </View>
                <View style={[styles.flexColumn, activityStyle.activityRightProperties]}>
                  {competitionsHonor.right &&
                    competitionsHonor.right.length > 0 &&
                    competitionsHonor.right.map((line) => (
                      <Text style={[styles.stone, styles.description, styles.line]} key={line}>
                        {line}
                      </Text>
                    ))}
                </View>
              </View>
            </React.Fragment>
          ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const MemorizedDocComponent = memo(DocComponent);

const PreviewPDFModal = ({
  data,
  studentId = '',
  userId = '',
  isLevel3,
}: {
  data: PDFDataType;
  studentId?: string;
  userId?: string;
  isLevel3: boolean;
}): JSX.Element => {
  const [isPDFDocumentRendered, setIsPDFDocumentRendered] = useState(false);
  const [pdfDocOnceRenderTime, setPdfDocOnceRenderTime] = useState(0);
  const onPDFDocumentRendered = useCallback((onceRenderTime: number) => {
    setPdfDocOnceRenderTime(onceRenderTime);
    setIsPDFDocumentRendered(true);
  }, []);

  useEffect(() => {
    return () => {
      setIsPDFDocumentRendered(false);
    };
  }, []);

  return (
    <div
      style={{
        width: '860px',
        overflowY: 'auto',
      }}
    >
      {(data?.loading || !isPDFDocumentRendered) && (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin
            style={{
              marginTop: '20px',
            }}
            tip="Compiling data for your PDF, this may take a few moments..."
          />
        </div>
      )}
      <div
        style={{
          visibility: !data?.loading && isPDFDocumentRendered ? 'visible' : 'hidden',
        }}
      >
        <div style={{ visibility: 'hidden' }}>Check Document Render Time: {pdfDocOnceRenderTime} ms</div>
        <PDFViewer
          style={{
            height: '80vh',
            width: '100%',
          }}
        >
          <MemorizedDocComponent
            data={data}
            studentId={studentId}
            userId={userId}
            onPDFDocumentRendered={onPDFDocumentRendered}
            isLevel3={isLevel3}
          />
        </PDFViewer>
      </div>
    </div>
  );
};

export default PreviewPDFModal;
