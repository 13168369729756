export const MIN_AGE_GROUP = 1;

export const MAX_AGE_GROUP = 13;

// 1 - 13
export const AGE_GROUP = new Array(MAX_AGE_GROUP).fill(1).map((_, idx) => ({
  label: `Grade ${idx + 1}`,
  value: (idx + 1).toString(),
}));

export const STAR_FILLED_ICON = '/static/resourceCenter/star_fill.svg';

export const STAR_FILLED_YELLOW_ICON = '/static/resourceCenter/star_fill_yellow.svg';

export const STAR_OUTLINE_ICON = '/static/resourceCenter/star_outline.svg';

export const ROCKET_FILLED_ICON = '/static/icon/rocket_red.png';

export const ROCKET_OUTLINE_ICON = '/static/icon/rocket_outline.png';

export const GREEN_TICK_ICON = '/static/resourceCenter/white_tick_green_background.svg';

export const LIST_DRAWER_ZINDEX = 1000;
export const DETAIL_DRAWER_ZINDEX = 1001;
export const ADD_MISSION_MODAL_ZINDEX = 1002;

export const COMPETITION_STATUS = [
  {
    label: 'Planned',
    value: 'PLANNED',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    label: 'Completed',
    value: 'DONE',
  },
];

export const LEVEL_OF_RECOGNITION = [
  {
    label: 'International',
    value: 'International',
  },
  {
    label: 'National',
    value: 'National',
  },
  {
    label: 'State Regional',
    value: 'State_Regional',
  },
  {
    label: 'School',
    value: 'School',
  },
];

export const COMPETITION_STATUS_MAP: Record<string, string> = COMPETITION_STATUS.reduce(
  (prev, curr) => ({ ...prev, [curr.value]: [curr.label] }),
  {},
);

export const ResourceCenterEvents = {
  openResourceCenter: 'ResourceCenter:Open',
  viewDetail: 'ResourceCenter:ViewDetail',
  createMission: 'ResourceCenter:CreateMission',
};

export const StaffDashboardMyStudentsEvents = {
  filterChange: 'StaffDashboardMyStudents:FilterChange',
  regionChange: 'StaffDashboardMyStudents:RegionChange',
  countryChange: 'StaffDashboardMyStudents:CountryChange',
  appYearChange: 'StaffDashboardMyStudents:AppYearChange',
  openShortLink: 'StaffDashboardMyStudents:OpenShortLink',
  columnClick: 'StaffDashboardMyStudents:ColumnClick',
  dashboardExpand: 'StaffDashboardMyStudents:Expand',
  itemClick: 'StaffDashboardMyStudents:ItemClick',
};
