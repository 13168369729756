import { useContext, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useCanViewInternalInfo } from 'src/hooks/useMissionHook/useIsStaffHook';
import { Modal } from 'antd';
import {
  StyledCategoryTab,
  StyledNavBar,
  StyledSubCategoryTab,
  StyledSubCategoryTabContainer,
  StyledRightOutlined,
  StyledCategoryLabelTab,
  StyledNavBarActiveBar,
  PreviewButton,
} from './style';

import PreviewPDFModal from '../Modals/PreviewPDFModal';
import { useFetchPDFData } from '../Modals/PreviewPDFModal/Hooks';
import { useFeatureFlag } from '@crimson-education/feature-switches-web';
import { Divider } from 'antd';
import * as Logger from '@crimson-education/browser-logger';
import { useTenantLevel3 } from 'src/hooks/useTenantLevel';

export const Categories = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    hideInPaths: ['/dashboard'],
    imgUrl: '/static/dashboard/overview.svg',
  },
  {
    label: 'Academics',
    link: '/academics',
    imgUrl: '/static/dashboard/academics.svg',
    subCategories: [
      { label: 'Education Information', link: '/academics#Education Information' },
      { label: 'School Academics', link: '/academics#School Academics' },
      { label: 'Summer Programs & University Courses', link: '/academics#University Courses & Summer Programs' },
      { label: 'Academic Research & Publications', link: '/academics#Academic Research & Publications' },
      { label: 'Creative Portfolios', link: '/academics#Creative Portfolios' },
    ],
  },
  {
    label: 'Testing',
    link: '/testing',
    imgUrl: '/static/dashboard/testing.svg',
    subCategories: [{ label: 'Testing Overview', link: '/testing#Testing Overview' }],
  },
  {
    label: 'Activities',
    link: '/activities',
    imgUrl: '/static/dashboard/activities.svg',
    subCategories: [
      { label: 'Extracurricular Activities', link: '/activities#Extracurricular Activities' },
      { label: 'Summer Programs & University Courses', link: '/activities#University Courses & Summer Programs' },
      { label: 'Academic Research & Publications', link: '/activities#Academic Research & Publications' },
      { label: 'Internship & Work Experience', link: '/activities#Internship & Work Experience' },
    ],
  },
  {
    label: 'Honors',
    link: '/honors',
    imgUrl: '/static/dashboard/honors_nav.svg',
    subCategories: [{ label: 'Competitions & Honors', link: '/honors#Competitions & Honors' }],
  },
  {
    label: 'Personal Development',
    link: '/personal-development',
    imgUrl: '/static/dashboard/personal_dev.svg',
    subCategories: [
      {
        label: 'College Admissions 101',
        link: '/personal-development#College Admissions 101',
      },
      { label: 'Readings', link: '/personal-development#Readings' },
      { label: 'Writings', link: '/personal-development#Writings' },
      { label: 'Essential Skills & Experiences', link: '/personal-development#Essential Skills & Experiences' },
      { label: 'Relationship Building', link: '/personal-development#Relationship Building' },
      {
        label: 'UK Non-Application Year (NAY) Curriculum',
        link: '/personal-development#UK Non-Application Year (NAY) Curriculum',
      },
      {
        label: 'Academic Skills & Essential Experiences',
        link: '/personal-development#Academic Skills & Essential Experiences',
      },
    ],
  },
  {
    label: 'Majors & Careers',
    link: '/career',
    imgUrl: '/static/dashboard/careers.svg',
    subCategories: [
      { label: 'School Research & Preparation', link: '/career#School Research & Preparation' },
      { label: 'Major, Career and Interest Exploration', link: '/career#Major, Career and Interest Exploration' },
      { label: 'Networking', link: '/career#Networking' },
    ],
  },
  {
    label: 'Personal Information',
    link: '/personal-info',
    imgUrl: '/static/dashboard/personal_info.svg',
    subCategories: [
      { label: 'Basic Information', link: '/personal-info#Basic Information' },
      { label: 'Applications', link: '/personal-info#Applications' },
      { label: 'Contact Details', link: '/personal-info#Contact Details' },
      { label: 'Internal Information', link: '/personal-info#Internal Information' },
    ],
  },
];

export const LEVEL_3_ONLY_SUBCATEGORY = [
  'Academic Skills & Essential Experiences',
  'UK Non-Application Year (NAY) Curriculum',
  'Networking',
  'Relationship Building',
];

const NavBar = (): JSX.Element => {
  const PDF_PREVIEW = useFeatureFlag('PDF_PREVIEW');

  const location = useLocation();
  const { currentUser, studentInfo } = useContext(CurrentUserContext);
  const canViewProfileInternalInfo = useCanViewInternalInfo(currentUser);
  const isCollegeWise = currentUser?.tenant?.name === 'collegewise';
  const isLevel3 = currentUser?.tenant?.level === 3;

  const [previewPDFVisible, setPreviewPDFVisible] = useState(false);

  const pdfData = useFetchPDFData({ isFeatureFlagOn: PDF_PREVIEW });
  const [params] = useSearchParams();

  const openIntroductionModal = async () => {
    const metadata = {
      studentId: studentInfo?.userId,
      userId: currentUser?.userId,
    };
    Logger.trackEvent({
      message: 'preview_pdf',
      metadata,
    });
    setPreviewPDFVisible(true);
    const pdfDataFetchStartTime = performance.now();
    await pdfData?.refetch();
    const pdfDataFetchTime = performance.now() - pdfDataFetchStartTime;
    const pdfDataFetchMetadata = {
      studentId: studentInfo?.userId,
      userId: currentUser?.userId,
      dataFetchTime: pdfDataFetchTime,
    };
    Logger.trackEvent({
      message: 'preview_pdf_data_fetch_performance',
      metadata: pdfDataFetchMetadata,
    });
  };

  const TENANT_LEVEL3 = useTenantLevel3();

  const getLink = (link: string) => {
    if (!link) {
      return '/';
    }
    if (params.toString()) {
      return link + '/?' + params.toString();
    }
    return link;
  };
  return (
    <StyledNavBar>
      {Categories.map(({ label, subCategories, link, imgUrl }, index) => {
        return (
          <div key={index}>
            <StyledCategoryTab replace={true} to={getLink(link)} $active={location.pathname === link}>
              <StyledCategoryLabelTab $active={location.pathname === link}>
                <img src={imgUrl} />
                <span> {label}</span>
              </StyledCategoryLabelTab>
              <StyledNavBarActiveBar $active={location.pathname === link} />
            </StyledCategoryTab>
            <StyledSubCategoryTabContainer>
              {location.pathname === link &&
                subCategories?.map(({ label, link }, i) => {
                  if (LEVEL_3_ONLY_SUBCATEGORY.includes(label) && !isLevel3) return null;
                  if (label === 'College Admissions 101' && !isCollegeWise) return null;
                  if (label === 'Internal Information' && !canViewProfileInternalInfo) return null;
                  return (
                    <StyledSubCategoryTab
                      key={i}
                      onClick={() => {
                        const el = document.getElementById((link as string).split('#')[1]);
                        el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      }}
                    >
                      <StyledRightOutlined />
                      {label}
                    </StyledSubCategoryTab>
                  );
                })}
            </StyledSubCategoryTabContainer>
          </div>
        );
      })}
      {PDF_PREVIEW && (
        <>
          <Divider />
          <PreviewButton
            icon={<img src="/static/icon/exportPDF.svg" />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openIntroductionModal();
            }}
            style={{
              width: '232px',
              height: '44px',
              margin: '0 auto',
            }}
          >
            Preview PDF
          </PreviewButton>
        </>
      )}
      {previewPDFVisible && (
        <Modal
          open={previewPDFVisible}
          getContainer={() => document.body}
          onCancel={() => {
            setPreviewPDFVisible(false);
          }}
          width={1000}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          bodyStyle={{
            overflowY: 'hidden',
          }}
          footer={null}
          closeIcon={null}
          closable={false}
        >
          <PreviewPDFModal
            studentId={studentInfo?.userId}
            userId={currentUser?.userId}
            data={pdfData}
            isLevel3={TENANT_LEVEL3}
          />
        </Modal>
      )}
    </StyledNavBar>
  );
};

export default NavBar;
