import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { QUERY_ROADMAP } from 'src/graphql/Roadmap';
import { useXUserId } from './useXprops';
import { notification } from 'antd';
let errorNotified = false;
const useRoadmapId = (): string => {
  const userId = useXUserId();
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const { data } = useQuery<{
    roadmap: {
      id: string;
    };
  }>(QUERY_ROADMAP, {
    variables: {
      studentId: userId,
    },
    skip: !userId,
    client: roadmapApiClient,
    onCompleted: (data) => {
      const queriedRoadmapId = data?.roadmap?.id;
      if (!queriedRoadmapId && !errorNotified) {
        errorNotified = true;
        notification.error({
          message: 'Roadmap id is undefined, please refresh the page',
        });
      }
    },
    onError: (err) => {
      if (!errorNotified) {
        errorNotified = true;
        console.error('Error fetching roadmapId:', err);
        notification.error({
          message: 'Roadmap id is undefined, please refresh the page',
        });
      }
    },
  });

  return data?.roadmap?.id ?? '';
};

export default useRoadmapId;
