import { useRef, MouseEvent, useContext } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { popupModalInfo, ModalReturnType } from 'src/utils/commonModal';
import {
  StyledContainer,
  StyledModalTitle,
  StyledModalDesc,
  StyledButtonGroup,
  StyledModalContainer,
  SubmitButton,
  CancelButton,
} from 'src/components/RemoveButton/style';
import { Popover } from 'antd';
import './index.less';
import { StyledAddToLibUl } from './style';
import { useIsStaff } from 'src/hooks/useMissionHook/useIsStaffHook';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

export const ConfirmModal = ({
  onClose,
  onConfirm,
  modalText,
  modalDesc = 'All the related data will be removed once done.',
  title = 'Are you sure',
}: {
  onClose: () => void;
  onConfirm: () => void;
  modalText: string;
  modalDesc?: string | null | React.ReactNode;
  title?: string;
}): JSX.Element => {
  return (
    <StyledModalContainer>
      <StyledModalTitle>{title}</StyledModalTitle>
      <StyledModalDesc>
        <div>{modalText}</div>
        {modalDesc && <div>{modalDesc}</div>}
      </StyledModalDesc>
      <StyledButtonGroup>
        <CancelButton width={150} onClick={onClose}>
          Cancel
        </CancelButton>
        <SubmitButton
          width={150}
          data-role="confirm-remove-mission"
          type="primary"
          htmlType="submit"
          onClick={onConfirm}
        >
          Confirm
        </SubmitButton>
      </StyledButtonGroup>
    </StyledModalContainer>
  );
};
const OperationButton = ({
  removeMission,
  Icon = <MoreOutlined style={{ color: '#A9ACC0', fontSize: '24px' }} />,
  addToMissionLib,
}: {
  removeMission: () => void;
  Icon?: React.ReactNode;
  addToMissionLib: () => void;
}): JSX.Element => {
  const modalRef = useRef<ModalReturnType | null>(null);
  const onConfirmRemoveMission = () => {
    modalRef.current?.destroy();
    removeMission();
  };
  const popupRemoveModal = (e: MouseEvent) => {
    modalRef.current = popupModalInfo({
      width: '500px',
      content: (
        <ConfirmModal
          onClose={() => {
            modalRef.current?.destroy();
          }}
          onConfirm={onConfirmRemoveMission}
          modalText="Are you sure you want to remove this mission?"
        />
      ),
      className: 'popup-modal-info-common-padding',
    });
    e.stopPropagation();
  };
  const popupAddToMissionLibModal = (e: MouseEvent) => {
    modalRef.current = popupModalInfo({
      width: '500px',
      content: (
        <ConfirmModal
          onClose={() => {
            modalRef.current?.destroy();
          }}
          modalText="Are you sure that you want to save the mission into mission library?"
          onConfirm={() => {
            modalRef.current?.destroy();
            addToMissionLib();
          }}
          modalDesc={
            <div>
              <div>The following information will be saved together:</div>
              <StyledAddToLibUl>
                <li>· Mission Title</li>
                <li>· Mission Description</li>
                <li>· Tasks</li>
              </StyledAddToLibUl>
            </div>
          }
        />
      ),
      className: 'popup-modal-info-common-padding',
    });
    e.stopPropagation();
  };
  const { currentUser } = useContext(CurrentUserContext);
  const isStaff = useIsStaff(currentUser);

  const content = (
    <div>
      <p onClick={popupRemoveModal}>Remove the mission</p>
      {isStaff && <p onClick={popupAddToMissionLibModal}>Add to mission library</p>}
    </div>
  );
  return (
    <StyledContainer>
      <Popover content={content} trigger="hover" overlayClassName="custom-popover">
        <div>{Icon}</div>
      </Popover>
    </StyledContainer>
  );
};

export default OperationButton;
