import { StrategistCountByRegion } from '@crimson-education/core-shared-graphql';
import { create } from 'zustand';

export interface ISuperScoreStore {
  superScoreForSAT: number;
  superScoreForACT: number;
  updateSuperScoreForAST: (newValue: number) => void;
  updateSuperScoreForACT: (newValue: number) => void;
}

export const useTestingSuperScore = create<ISuperScoreStore>((set) => ({
  superScoreForSAT: 0,
  updateSuperScoreForAST: (newValue: number) =>
    set((state: ISuperScoreStore) => ({
      ...state,
      superScoreForSAT: newValue,
    })),
  superScoreForACT: 0,
  updateSuperScoreForACT: (newValue: number) =>
    set((state: ISuperScoreStore) => ({
      ...state,
      superScoreForACT: newValue,
    })),
}));

export interface ILimitCWUserEditMissionStore {
  isLimitCWUserEditMission: boolean;
  updateIsLimitCWUserEditMission: (newValue: boolean) => void;
}

export const useLimitCWUserEditMission = create<ILimitCWUserEditMissionStore>((set) => ({
  isLimitCWUserEditMission: false,
  updateIsLimitCWUserEditMission: (newValue: boolean) =>
    set((state: ILimitCWUserEditMissionStore) => ({
      ...state,
      isLimitCWUserEditMission: newValue,
    })),
}));

export type StrategistListFilter = {
  userIds: string[];
  regions: string[];
  managerIds: string[];
  divisions: string[];
};

export interface IStrategistListSelectorStore {
  filter: StrategistListFilter;
  updateFilter: (newValue: StrategistListFilter) => void;
  refetchListSignal: number;
  setRefetchListSignal: () => void;
  countOfRegion: StrategistCountByRegion;
  setCountOfRegion: (newValue: StrategistCountByRegion) => void;
}

export const useStrategistListSelectorStore = create<IStrategistListSelectorStore>((set) => ({
  filter: {
    userIds: [],
    regions: [],
    managerIds: [],
    divisions: [],
  },

  updateFilter: (newValue: StrategistListFilter) => {
    set((state: IStrategistListSelectorStore) => ({
      ...state,
      filter: newValue,
    }));
  },

  refetchListSignal: 0,
  setRefetchListSignal: () => {
    set((state: IStrategistListSelectorStore) => ({
      ...state,
      refetchListSignal: state.refetchListSignal + 1,
    }));
  },
  countOfRegion: {
    withinRegionCount: 0,
    sisterRegionCount: 0,
    outOfRegionCount: 0,
  },
  setCountOfRegion: (newValue: StrategistCountByRegion) => {
    set((state: IStrategistListSelectorStore) => ({
      ...state,
      countOfRegion: newValue,
    }));
  },
}));

export interface IActivityOrderObserverState {
  currentUpdatedMissionId: string;
  newOrder: number;
  oldOrder: number;
  timestamp: string;
  updateActivityOrderObserverState: (missionId: string, newOrder: number, oldOrder: number) => void;
  updateTimestamp: () => void;
}

export const useActivityOrderObserverState = create<IActivityOrderObserverState>((set) => ({
  currentUpdatedMissionId: '',
  newOrder: -1,
  oldOrder: -1,
  timestamp: '',
  updateActivityOrderObserverState: (missionId: string, newOrder: number, oldOrder: number) =>
    set((state: IActivityOrderObserverState) => ({
      ...state,
      currentUpdatedMissionId: missionId,
      newOrder: newOrder,
      oldOrder: oldOrder,
      timestamp: Date.now() + '',
    })),
  updateTimestamp: () =>
    set((state: IActivityOrderObserverState) => ({
      ...state,
      timestamp: Date.now() + '',
    })),
}));
